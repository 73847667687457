<div *ngIf="loading; else content">
  <app-spinner></app-spinner>
</div>
<ng-template #content>
  <div class="container" *ngIf="applications">
    <div class="filter-row mb-5">
      <div class="select-container">
        <ejs-dropdownlist
          id="positions"
          placeholder="Position"
          [dataSource]="positions"
          (change)="applyFilter($event.value, 'position')"
          [showClearButton]="true"
          [fields]="fields"
          [itemTemplate]="itemTemplate"
          width="380px"
        >
        </ejs-dropdownlist>
        <ng-template #itemTemplate let-data>
          {{ data.jobTitle }} - {{ getTeamValue(data.team) }} | {{ getLocationValue(data.location) }}
        </ng-template>
      </div>

      <div class="select-container">
        <ejs-dropdownlist
          id="team"
          [dataSource]="teams"
          placeholder="Team"
          (change)="applyFilter($event.value, 'team')"
          [showClearButton]="true"
          width="180px"
        >
        </ejs-dropdownlist>
      </div>
      <div class="select-container">
        <ejs-dropdownlist
          id="location"
          [dataSource]="locations"
          placeholder="Location"
          (change)="applyFilter($event.value, 'location')"
          [showClearButton]="true"
          width="180px"
        >
        </ejs-dropdownlist>
      </div>
      <div class="select-container">
        <ejs-dropdownlist
          id="jobType"
          [dataSource]="jobTypes"
          placeholder="Job Type"
          (change)="applyFilter($event.value, 'jobType')"
          [showClearButton]="true"
          width="180px"
        >
        </ejs-dropdownlist>
      </div>
      <div class="select-container">
        <ejs-checkbox id="isClosed" label="Show Closed" (change)="applyFilter($event.checked, 'closed')"></ejs-checkbox>
      </div>
    </div>
    <ejs-grid
      [dataSource]="filteredApplications"
      [allowPaging]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [enableHover]="true"
      [pageSettings]="pageSettings"
      [allowSelection]="false"
      [allowResizing]="true"
      height="700px"
    >
      <e-columns>
        <e-column
          field="candidate.displayName"
          headerText="Candidate"
          cssClass="candidate-name-cell"
          textAlign="Center"
        >
          <ng-template #template let-data>
            <a *ngIf="data.candidate" [href]="getApplicationDetailUrl(data)" target="_blank">{{ data.candidate.displayName }}</a>
            <span *ngIf="!data.candidate">N/A</span>
          </ng-template>
        </e-column>
        <e-column field="position.location" headerText="Location" width="90" textAlign="Center">
          <ng-template #template let-data>
            {{ getLocationValue(data.position.location) }}
          </ng-template>
        </e-column>
        <e-column field="status" headerText="Status" width="180" textAlign="Center">
          <ng-template #template let-data>
            {{ getStatusValue(data.status) }}
          </ng-template>
        </e-column>
        <e-column field="mostRecentJobTitle" headerText="Most Recent Company" width="150" textAlign="Center"></e-column>
        <e-column field="mostRecentCompany" headerText="Most Recent Job Title" width="150" textAlign="Center"></e-column>

        <e-column field="expectedSalary" headerText="Expected Salary" width="120" textAlign="Center"></e-column>
        <e-column field="availability" headerText="Availability" width="100" textAlign="Center"></e-column>
        <e-column
          field="created"
          headerText="Submitted"
          type="date"
          format="dd-MM-yyyy"
          width="100"
          textAlign="Center"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</ng-template>
